.inputField {
    border: none;
    border-radius: 50px;
    padding-left: 10px;
    border: 2px solid rgb(239 239 239);
}

.inputField:focus {
    outline: none;
}

.inputTitle {
    margin-bottom: 5px;
    font-weight: 600;
    color: #6a6a6a;
}

a:first-child[tabindex="-1"], div:first-child[class="tox-notifications-container"]  {
    display: none;
}

.whiteBox {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}

.mainBox {
    min-height: 90vh;
    border-top-left-radius: 30px;
    position: fixed;
    overflow-y: auto;
    left: 155px;
    min-width: 90vw;
    top: 105px;
    z-index: 1;
    background-color: #e9e9e9;
}

.fileUploader > label {
    border: 2px dashed #707070;
    border-radius: 0px;
    max-width: none;
    height: 150px;
    background-color: white;
}

.fileUploader > label > svg {
    display: none;
}

.fileUploader > label > div {
    display: flex;
    justify-content: center;
}

.fileUploader > label > div > span  {
    visibility: hidden;
}

.fileUploader > label > div > span::before  {
    visibility: visible;
    content: "Sleep of selecteer uw media-bestanden om te uploaden";
    position: relative; 
    left: 75px;
    white-space: nowrap;
}

.optionsList {
    border: none;
    border-radius: 50px;
    padding-left: 10px;
}

.optionsList:focus {
    outline: none;
}

.buttonStyle {
    background-color: #b5b5b5;
    color: #000000;
    font-weight: 500;
    border-radius: 50px;
}

.buttonStyle:hover {
    background-color: #929292;
    color: #000000;
    font-weight: 500;
    border-radius: 50px;
}

.hover:hover {
    cursor: pointer;
}

.fitContendHeight {
    height: fit-content !important;
}

.fitContendWidth {
    width: fit-content !important;
}

.widthInherit {
    width: inherit !important;
}

@media only screen and (min-width: 1320px) {
    .mainBox {
        min-height: 90vh;
        border-top-left-radius: 30px;
        position: fixed;
        overflow-y: auto;
        left: 190px;
        min-width: 90.1vw;
        top: 105px;
        z-index: 1;
        background-color: #e9e9e9;
    }
}


@media only screen and (max-width: 926px) {

    .mainBox {
        min-height: 90vh;
        border-top-left-radius: 30px;
        position: fixed;
        overflow-y: auto;
        left: 125px;
        min-width: 89vw;
        top: 105px;
        z-index: 1;
        background-color: #e9e9e9;
    }
}

@media only screen and (max-width: 768px) {

    .mainBox {
        min-height: 90vh;
        border-top-left-radius: 30px;
        position: fixed;
        overflow-y: auto;
        left: 100px;
        min-width: 89vw;
        top: 105px;
        z-index: 1;
        background-color: #e9e9e9;
    }
}