.debitSettingBox {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 5px;
    border: solid 2px rgba(0, 0, 0, 0.075);
    /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.192); */
}

.debitSettingInputBox > span#name {
    width: 150px;
    margin-right: 10px;
    white-space: nowrap;
}

input[type='number']#interval_value {
    width: 50px !important;
    border-radius: 10px 0px 0px 10px;
}

select {
   width: 160px !important;
   flex: none !important;
   border: none !important;
   transition: all 0.3s ease;
   border-radius: 0px 10px 10px 0px;
   padding: 7.5px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

select:focus {
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input[type='number'].numberInputStyle, input[type='text'], input[type='date'] {
    padding: 5px;    
    font-size: 16px;
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

input[type='date'] {
    width: 210px;
}

input[type='text']:focus, input[type='number'].numberInputStyle:focus, input[type='date']:focus {
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input[type='text']:disabled, input[type='number'].numberInputStyle:disabled, select:disabled, input[type='date']:disabled {
    background-color: #f0f0f0;
}


.extraDebitTransactionInfo {
    color: rgb(155, 155, 155);
}

.crossRed {
    color: rgb(255, 0, 0);
}

.cirleOrange {
    color: rgb(255, 145, 0);
}

.circleBlue {
    color: rgb(0, 195, 255);
}

.checkmarkGreen {
    color: rgb(8, 228, 0);
}

.checkmarkGray {
    color: rgb(201, 201, 201);
}

.incassoError {
    display: none;
    top: 20px;
    position: absolute;
    color: red;
    font-size: 10px;
    white-space: normal;
    width: 200px;
}

.debit-button {
    min-width: 185px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-debit {
    background-color: #4caf50;
}

.cancel-debit {
    background-color: #d43a3a;
}

.edit-debit {
    background-color: #3ab0d4;
}

.debitButtonContainer {
    display: flex;
    gap: 20px;
}

.questionCircle {
    color: #5782b3;
}

@media only screen and (max-width: 500px) {
    .debitSettingInputBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        gap: 3px;
    }

    .debitButtonContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .debit-button {
        width: 100%;
    }

    input[type='date'] {
        width: auto;
    }
}

@media only screen and (max-width: 350px) {
    select {
        width: 130px !important;
    }
}