.crossRed {
    color: rgb(255, 0, 0);
}

.circleOrange {
    color: rgb(255, 145, 0);
}

.circleBlue {
    color: rgb(0, 195, 255);
}

.checkMarkGreen {
    color: rgb(8, 228, 0);
}

.checkMarkGray {
    color: rgb(165, 165, 165);
}

.bodyMaxVertical {
    max-height: 90vh !important;
    overflow-y: scroll;
}

.tableMinWidth {
    overflow-x: auto;
    width: 50vw;
    min-width: calc(100vw - 300px);
}

.debitTypeButton {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: rgb(248, 248, 248);
    transition: all ease 0.3s;
}

.debitTypeButton:hover {
    background-color: rgb(233, 233, 233);
}

.debitTypeButton#debitSelected {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.171);
}

thead {
    position: sticky;
    top: 0px;
}

.textNoWrap {
    white-space: nowrap;
}

@media only screen and (max-width: 382px) {
    .pageTitle {
        width: 50vw !important;
    }

    .tableBox {
        width: 84.5vw;
    }

    .tableMinWidth {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .tableMinWidth {
        min-width: calc(100vw - 100px);
    }
}

thead > tr > th {
    white-space: nowrap;
}