body {
    font-family: 'Open Sans', sans-serif;
}

.mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text-center {
    text-align: center;
}

.extraInformation {
    color: rgb(128, 128, 128);
}

.errorText {
    color: red;
    display: none;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    color: #212529;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15); */
}

td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

th {
    background-color: #f8f9fa;
    font-weight: 600;
}