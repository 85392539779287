body {
    max-width: 100vw;
}

.sideBarContainer {
    background-color: rgb(245, 245, 245);
    min-width: 200px;
    max-height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
    position: fixed;
    padding-right: 10px;
    position: fixed;
    left: 0px;
    top: 0px;
}

.navbarIconTextContainer {
    margin-bottom: 10px;
    display: flex;
    transition: all 0.3s ease;
    color: rgb(94, 94, 94);
    text-decoration: none;
}

.navbarIconTextContainer:hover {
    color: rgb(0, 0, 0);
    cursor: pointer;
}  

.sideBarIcon {
    font-size: 25px;
}

.sideBarText {
    padding-top: 7px !important;
    margin-left: 5px;
    line-height: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.mainContainer {
    margin-left: 200px;
    display: flex;
    position: absolute;
    width: calc(100vw - 200px);
}

@media only screen and (max-width: 768px) {
    .sideBarText {
        display: none;
    }

    .topIcon {
        display: none;
    }

    .sideBarContainer {
        padding-top: 10px;
        min-width: 25px;
        align-items: center;
    }

    .mainContainer {
        margin-left: 50px;
        width: calc(100vw - 50px);
    }
}
