body {
    font-family: 'Open Sans', sans-serif;
}

.pages-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.loading-icon {
    width: 40px !important;
    height: 40px !important;
}

.page-display {
    background-color: rgb(250, 250, 250);
    border: 3px solid rgb(204, 204, 204);
    border-radius: 10px;
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;
}

.page-display:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
}

.page-display-title {
    font-size: 30px;
    text-align: center;
    color: black;
}

.edit-page-icon {
    position: absolute;
    top: 5px;
    right: 5px;
}

.new-page-icon {
    width: 100%;
    height: 50%;
}