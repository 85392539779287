body {
    font-family: 'Open Sans', sans-serif;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by most modern browsers */
}

.donateTitle {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
}

.donateMethodTitle {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.transactionTypeContainer {
    position: relative;
}

.transactionTypeButton {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.123);
    padding: 10px;
    border: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.transactionTypeButton:hover {
    cursor: pointer;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.226);
}

/* .transactionTypeButton#selected {
    box-shadow: 0 3px 0 rgb(255, 166, 0) !important;
} */

.transactionTypeButton > span {
    font-size: 20px;
    transition: all 0.3s ease;
}

/* .transactionTypeButton#selected > span {
    color: rgb(255, 166, 0);
} */

.transactionAmountButton {
    border-radius: 10px;
    min-width: 150px;
    width: 30%;
    white-space: nowrap;
    height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    border: none;
    background-color: #ffffff;
    padding: 10px 20px;
    margin: 10px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s ease;
}

@media only screen and (max-width: 370px) {
    .transactionAmountButton {
        min-width: 90%;
    }
  }

.transactionAmountButton:hover {
    background-color: #fcfcfc;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

#selected {
    box-shadow: 0 0 0 2px rgba(255, 166, 0);
}

.customAmountInputField {
    border: none;
    width: calc(100% - 13px);
    left: 13px;
    position: relative;
    text-align: center;
    background-color: inherit;
    box-shadow: none !important;
}

.customAmountInputField:disabled:hover {
    cursor: pointer;
}

.customAmountInputField {
    border: none;
    outline: none;
}
  
.customAmountInputField::-webkit-inner-spin-button,
.customAmountInputField::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}

.transactionMethodButton {
    border-radius: 10px;
    width: 10%;
    height: 70px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    border: none;
    background-color: #ffffff;
    padding: 3px;
    margin: 10px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    min-width: 70px;
    position: relative;
    transition: all 0.3s ease;
}

.transactionMethodButton:hover {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

.donateButton {
    --custom-color: 0, 0, 0;
    background-color: var(--custom-color);
    border-radius: 10px;
    width: 30%;
    min-width: 100px;
    height: 50px;
    font-size: 20px;
    color: rgb(0, 0, 0);
    border: none;
    font-weight: bolder;
    padding: 10px 20px;
    margin: 10px;
    position: relative;
    transition: all 0.3s ease;
}

.donateButton:hover {
    box-shadow: 0 0 0 4px rgba(var(--custom-color), 0.3);
}

.transactionMethodButton > img {
    max-height: 60px;
    max-width: 100%;
    width: auto;
    height: auto;
}

.disclaimer {
    color: rgba(0, 0, 0, 0.205);
    font-size: 12px;
}