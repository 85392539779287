body {
    font-family: 'Open Sans', sans-serif;
}

#errorMessage {
    color: red;
    font-weight: bold;
    display: none;
}

.settingGroup {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 5px;
    border: solid 2px rgba(0, 0, 0, 0.075);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    min-width: fit-content !important;
}

.inputContainer {
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
}

input[type='text'], input[type='number'] {
    padding: 5px;    
    font-size: 16px;
    border: none;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}
  
input[type='text']:focus, input[type='number']:focus {
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input[type="color"] {
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
}

input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
}

input[type="color"]::-moz-color-swatch {
    border: none;
    border-radius: 50%;
    padding: 0;
}

input[type="color"]::-moz-focus-inner {
    border: none;
}

/* Optional: Add a custom border or box shadow */
input[type="color"] {
    border: 2px solid #000000;
}

.save-button {
    width: 25%;
    min-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #45a049;
}

.save-button:active {
    background-color: #3e8e41;
}

.back-button {
    width: 25%;
    min-width: 200px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #d1d1d1;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #c9c9c9;
}

.back-button:active {
    background-color: #bbbbbb;
}

@media only screen and (max-width: 400px) {
    .inputContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        gap: 3px;
    }
}

.loading-icon {
    width: 40px !important;
    height: 40px !important;
}