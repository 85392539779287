.crossRed {
    color: rgb(255, 0, 0);
}

.circleOrange {
    color: rgb(255, 145, 0);
}

.circleBlue {
    color: rgb(0, 195, 255);
}

.checkMarkGreen {
    color: rgb(8, 228, 0);
}

.checkMarkGray {
    color: rgb(165, 165, 165);
}

.bodyMaxVertical {
    max-height: 500px !important;
    overflow-y: scroll;
}

.textNoWrap {
    white-space: nowrap;
}

.analyticContainer {
    height: 200px;
    position: relative;
    background-image: linear-gradient(45deg, rgb(187, 187, 187), rgb(236, 236, 236));
    margin: 10px;
}

.analyticAmount {
    font-size: 45px;
    font-weight: bold;
    color: rgb(126, 126, 126);
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;
}

.analyticTitle {
    font-size: 20px;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 20px;
    color: rgba(255, 255, 255, 0.623);
}

.analyticsButton {
    background-color: rgb(245, 245, 245);
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding: 10px !important;
    transition: all ease 0.3s;
    white-space: nowrap;
}

.analyticsButton:hover {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.062);
}

.analyticsButton#selectedAnalytics {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.199);
}

.anaylyticsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.dateSideBarContainer {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
}

.mainAnalyticsContainer {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .dateSideBarContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .mainAnalyticsContainer {
        display: block;
    }
}